<template>
  <div class="add-service">
    <h1 class="admin-title">{{$t('Add Service')}}</h1>
    <ValidationObserver ref="validator">
      <form @submit.prevent>
        <b-row>
          <b-col cols="12" sm="6" md="4">
            <RFValidation name="Name" rules="required">
              <RFInput label="Name" v-model="service.name" />
            </RFValidation>
          </b-col>
          <b-col cols="12" sm="6" md="4">
            <RFValidation name="Description" rules="required">
              <RFInput label="Description" v-model="service.description" />
            </RFValidation>
          </b-col>
          <b-col cols="12" sm="6" md="4">
            <RFValidation name="Price" rules="required">
              <RFCurrencyInput label="Price" v-model="service.price" />
            </RFValidation>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" sm="6" md="4">
            <RFValidation name="Type" rules="required">
              <RFSelect top-label="Type" v-model="selectedServiceType" @input="service.typeId = $event" :list="serviceTypeList"/>
            </RFValidation>
          </b-col>
          <b-col cols="12" sm="6" md="4">
            <RFCheckbox class="mt-4" label="Recurring" v-model="service.isRecurrent" />
          </b-col>
          <b-col cols="12" sm="6" md="4">
            <RFCheckbox class="mt-4" label="Enabled" v-model="service.isEnabled" />
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <div class="admin-subtitle d-flex justify-content-between mt-5">{{$t('Icon')}}</div>
          </b-col>
          <b-col class="mt-3">
            <AdminImageList @images="addIcon" :limit="1" :is-accommodation="false" min-height="250px" :multiple="false"/>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <div class="admin-subtitle d-flex justify-content-between mt-5">{{$t('Images')}}</div>
          </b-col>
          <b-col class="mt-3">
            <AdminImageList @select="selectedImageIndex = $event" @images="images = $event" :is-accommodation="false"  min-height="250px"/>
          </b-col>
        </b-row>

        <b-row class="justify-content-end mt-5">
          <b-col cols="auto">
            <RFButton class="mt-3" label="Save" background="#5CB85C" v-if="!loading" @on-click="save"/>
            <RFLoader class="mt-3" color="#24425B" :size="30" v-else/>
          </b-col>
        </b-row>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import RFValidation from '@/components/forms/RFValidation'
import RFCurrencyInput from '@/components/forms/RFCurrencyInput'
import RFInput from '@/components/forms/RFInput'
import RFRadio from '@/components/forms/RFRadio'
import RFSelect from '@/components/forms/RFSelect'
import RFButton from '@/components/forms/RFButton'
import RFLoader from '@/components/forms/RFLoader'
import RFCheckbox from '@/components/forms/RFCheckbox'
import RFFile from '@/components/forms/RFFile'

import AdminImageList from '@/components/admin/AdminImageList'

import {additionalService} from '@/services/admin'

import {mapState} from 'vuex'

export default {
  name: 'AdminAddService',
  components: {
    RFValidation,
    RFCurrencyInput,
    RFInput,
    RFRadio,
    RFSelect,
    RFCheckbox,
    RFFile,
    RFButton,
    RFLoader,

    AdminImageList,
  },
  data() {
    return {
      service: {
        name: null,
        description: null,
	      price: null,
	      isRecurrent: false,
	      isEnabled: false,
        typeId: null,
      },
      selectedServiceType: null,
      icon: null,
      images: [],
      loading: false,
      serviceTypeList: [],
    }
  },
  computed: {
    ...mapState({
      serviceTypes: state => state.adminServiceTypeStore.serviceTypes
    })
  },
  beforeMount() {
    this.serviceTypeList = this.serviceTypes.map(serviceType => ({ label: serviceType.name, value: serviceType.id }))
  },
  watch: {
    'service.typeId': {
      handler() {
        this.selectedServiceType = this.serviceTypeList.find(serviceType => serviceType.id === this.service.typeId).name
      }
    }
  },
  methods: {
    addIcon(images) {
      this.icon = images[0]
    },
    async save() {
      const isValid = await this.$refs.validator.validate()

      if (isValid) {
        this.loading = true
        const formData = new FormData()

        formData.append('service', new Blob([JSON.stringify(this.service)], { type: 'application/json' }))
        this.images.forEach(image => formData.append('files', image))
        formData.append('icon', this.icon)

        const { status } = await additionalService.addService(formData)

        if (status === 200) {
          this.$toasted.success(this.$i18n.t(`Service successfully added!`))
          this.$router.push({ name: 'service-list' })
        } else {
          this.$toasted.error(this.$i18n.t(`Error during add service`))
        }

        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.add-service {
  .icon {
    max-width: 100%;
  }
  .service-section {
    margin-top: 40px;

    .header {
      border-bottom: 1px solid $dark-gray;
    }
    .section-title {
      @include font-style($montserrat, 'semibold', $font-22);
      color: $dark-blue;
      text-transform: uppercase;
      margin: 0;
    }
    .add-images-label {
      @include font-style($montserrat, 'semibold', $font-16);
      color: $success;
    }
  }
  // .images {
  //   border: 1px solid $gray;
  //   min-height: 200px;

  //   .image-container {
  //     position: relative;
  //     width: 100%;

  //     &:hover {
  //       .hover {
  //         opacity: 1;
  //         z-index: 5;
  //       }
  //     }
  //     .hover {
  //       position: absolute;
  //       width: 100%;
  //       height: 100%;
  //       top: 0;
  //       left: 0;
  //       display: flex;
  //       align-items: center;
  //       justify-content: center;
  //       background-color: rgba($black, .6);
  //       opacity: 0;
  //       z-index: -1;
  //       transition: opacity .3s, z-index .3s;
  //     }
  //   }
  // }
}
</style>
